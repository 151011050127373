import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import FormattedParameters from '../../components/FormattedParameters.jsx';
import MaterialForm from '../../components/MaterialForm.jsx';

const sortOptions = {
  defaultSort: {
    field: 'Serial',
    reverse: false,
  },
};

function EquipmentTable({
  // Props
  equipment,
  part,
  setTableReload,
}) {
  const [sortedEquipment, handleSortSelection, sort] = useSort(equipment, sortOptions);

  return (
    <Box>
      <Grid container spacing={2} columns={16} px={1} alignItems="flex-end" justifyContent="center">
        <Grid item xs={16}>
          <Typography variant="h4" align="center">
            Equipment
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <ColumnHeader
            id="Name"
            display="Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={5} sm={2}>
          <ColumnHeader
            id="Serial"
            display="Serial Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={5} sm={2}>
          <ColumnHeader
            id="Location"
            display="Location"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader id="MaterialPartNumber" display="Material PN" />
        </Grid>
        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader id="MaterialDescription" display="Material Description" />
        </Grid>
        <Grid item sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader id="MaterialParameters" display="Material Parameters" />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedEquipment} displayName="equipment" />
      {sortedEquipment.map((unit) => (
        <React.Fragment key={`${unit.Serial}`}>
          {unit.Materials.map((material) => (
            <React.Fragment key={`${unit.Serial}-${material.PartNumber}`}>
              <Grid container spacing={2} columns={16}>
                <Grid item xs={6} sm={2}>
                  {unit.Name}
                </Grid>
                <Grid item xs={5} sm={2}>
                  {unit.Serial}
                </Grid>
                <Grid item xs={5} sm={2}>
                  {unit.Location}
                </Grid>
                <Grid item xs={6} sm={2}>
                  {material.PartNumber}
                </Grid>
                <Grid item xs={10} sm={4}>
                  {material.Description}
                </Grid>
                <Grid item xs={16} sm={4}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <FormattedParameters Parameters={material.Parameters} />
                    </Grid>
                    <Grid item>
                      <MaterialForm
                        material={material}
                        equipment={unit}
                        part={part}
                        parameterList={material.Parameters?.ParameterList}
                        setTableReload={setTableReload}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ mx: -1 }} />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default EquipmentTable;
