import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import CancelButton from './common/CancelButton.jsx';

function capitalize(text) {
  // Multiple spaces in a row can result in empty strings being passed here
  if (!text) {
    return text;
  }
  if (!text.includes(' ')) {
    return text[0].toUpperCase() + text.substr(1).toLowerCase();
  }
  return text
    .split(' ')
    .map((str) => capitalize(str))
    .join(' ');
}

function ConfirmDisable({
  // Props
  handleConfirmation,
  confirmKeyword,
  name,
  dialogTitle,
}) {
  const [open, setOpen] = React.useState(false);

  const closeForm = React.useCallback(() => setOpen(false), []);
  const handleClick = React.useCallback(() => setOpen((prev) => !prev), []);
  const handleConfirmClick = React.useCallback(() => {
    setOpen(false);
    handleConfirmation();
  }, [handleConfirmation]);

  // We need this because just using the 'text-transform' property is not enough.
  // 'text-transform' doesn't actually change the text inside the div it just changes how it displays.
  const disableButtonText = capitalize(`Disable ${name}`);
  const disableDialogTitleText = capitalize(
    `Disable ${name}${dialogTitle ? ` - ${dialogTitle}` : ''}`
  );

  const [textInput, setTextInput] = React.useState('');
  const handleChange = React.useCallback(
    (event) => setTextInput(event.target.value),
    [setTextInput]
  );
  return (
    <>
      <Button
        variant="text"
        data-cy={`${name}-disable-link`}
        sx={{
          marginLeft: 'auto',
          marginRight: '2.5rem',
          color: 'text.secondary',
          '&:hover': { textDecoration: 'underline' },
        }}
        onClick={handleClick}
      >
        {disableButtonText}
      </Button>
      <Dialog open={open} onClose={closeForm} maxWidth="sm" fullWidth>
        <DialogTitle>{disableDialogTitleText}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText data-cy="disable-confirmation-text">
            If you are sure you want to disable this {name.toLowerCase()}, type{' '}
            <strong>{confirmKeyword}</strong> into the field below and press the confirmation
            button.
          </DialogContentText>
          <br />
          <TextField
            fullWidth
            variant="outlined"
            onChange={handleChange}
            inputProps={{ 'data-cy': 'disable-selected-text-field' }}
          />
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton color="secondary" onClick={closeForm}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <Button
              disabled={textInput !== confirmKeyword}
              data-cy={`${name}-disable-confirm`}
              variant="contained"
              color="secondary"
              onClick={handleConfirmClick}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmDisable;
