import React from 'react';
import { DateTime } from 'luxon';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import MaintenanceActivitiesForm from '../../components/MaintenanceActivityForm.jsx';

const sortOptions = {
  defaultSort: {
    field: 'ActivityDate',
    // show the newest entry first
    reverse: true,
  },
};

function MaintenanceActivitiesTable({ maintenanceActivities, equipment, setTableReload }) {
  const [sortedActivities, handleSortSelection, sort] = useSort(maintenanceActivities, sortOptions);

  return (
    <Box sx={{ mb: 2, mt: 3 }}>
      <Grid container columns={15} spacing={2} px={1} alignItems="flex-end" justifyContent="center">
        <Grid item xs={15}>
          <Typography variant="h4" align="center">
            Maintenance Activities
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="ActivityDate"
            display="Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="MaintainerEmail"
            display="Operator"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="Notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="CurrentOperatingHours"
            display="Current Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="MaintenanceRequirements"
            display="Maintenance Requirements"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedActivities} displayName="maintenance activities" />{' '}
      {sortedActivities.map((activity) => (
        <React.Fragment key={`${activity.ActivityDate}-${activity.CurrentOperatingHours}`}>
          <Grid
            container
            columns={15}
            spacing={2}
            px={1}
            data-cy={`maintenance-activity-${activity.CurrentOperatingHours}-${activity.MaintainerEmail}`}
          >
            <Grid item xs={3}>
              {DateTime.fromISO(activity.ActivityDate).toLocaleString(DateTime.DATE_SHORT)}
            </Grid>
            <Grid item xs={3}>
              {activity.MaintainerEmail}
            </Grid>
            <Grid item xs={3}>
              {activity.Notes ? <FormattedMultilineRow cell={activity.Notes} /> : '-'}
            </Grid>
            <Grid item xs={3}>
              {activity.CurrentOperatingHours}
            </Grid>
            <Grid item xs={3}>
              {activity.MaintenanceRequirements.map((mr) => {
                const display = `${mr.EquipmentSerial} - ${mr.IntervalPeriod} ${mr.IntervalUnits}`;
                return (
                  <div key={display} data-cy={display}>
                    {display}
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            <MaintenanceActivitiesForm equipment={equipment} setTableReload={setTableReload} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MaintenanceActivitiesTable;
