import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------

function NoneFound({
  // Props
  list,
  displayName,
}) {
  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  if (list.length) {
    return <Typography />;
  }

  return (
    <Typography align="center" sx={{ m: 3 }}>
      No {displayName} found
    </Typography>
  );
}

export default NoneFound;
