import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import DocumentContext from '../../contexts/DocumentContext.jsx';

const sortOptions = {
  defaultSort: {
    field: 'WorkInstructionId',
    reverse: false,
  },
};

function WorkInstructionsTable({
  // Props
  workInstructions,
}) {
  //---------------------------------------------------------------------------
  // Use metadata from the document management system
  //---------------------------------------------------------------------------
  const { documents } = React.useContext(DocumentContext);
  const instructionsWithDocuments = React.useMemo(() => {
    if (!documents.length) {
      return workInstructions.map((instruction) => ({
        ...instruction,
        last_approved_version: <CircularProgress size="1rem" />,
        name: <CircularProgress size="1rem" />,
      }));
    }
    return workInstructions.map((instruction) => {
      const document = documents.find((doc) => doc.id === instruction.WorkInstructionId);
      return { ...instruction, ...document };
    });
  }, [documents, workInstructions]);

  //---------------------------------------------------------------------------
  // Sorting support
  //---------------------------------------------------------------------------
  const [sortedWorkInstructions, handleSortSelection, sort] = useSort(
    instructionsWithDocuments,
    sortOptions
  );

  return (
    <Box sx={{ my: 3 }}>
      <Grid container spacing={2} px={1} alignItems="flex-end" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Work Instructions
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="WorkInstructionId"
            display="Work Instruction"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={3}>
          <ColumnHeader
            id="last_approved_version"
            display="Revision"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={6}>
          <ColumnHeader
            id="name"
            display="Description"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedWorkInstructions} displayName="work instructions" />
      {sortedWorkInstructions.map((doc) => (
        <React.Fragment key={doc.WorkInstructionId}>
          <Grid container spacing={2} px={1}>
            <Grid item xs={3}>
              {doc.WorkInstructionId}
            </Grid>
            <Grid item xs={3}>
              {doc.last_approved_version || 'NOT APPROVED!'}
            </Grid>
            <Grid item xs={6}>
              {doc.name}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default WorkInstructionsTable;
