import React from 'react';
import { useLocation } from 'react-router-dom';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItem from '@mui/material/ListItem';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import Pagination from '../../components/common/Pagination.jsx';
import EditButton from '../../components/EditButton.jsx';
import EquipmentForm from '../../components/EquipmentForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import EquipmentHeader from './EquipmentHeader.jsx';
import EquipmentRow from './EquipmentRow.jsx';

//-----------------------------------------------------------------------------
// Page configuration
//-----------------------------------------------------------------------------
const DATA_REFRESH_INTERVAL_MS = 15000;
const searchFields = {
  serial: 'Serial',
  name: 'Name',
  location: 'Location',
};
const searchHelper = [
  { label: 'Has the words', keyword: '+', variant: 'global' },
  { label: "Doesn't have", keyword: '-', variant: 'global' },
  { label: 'Serial Number', keyword: 'serial', variant: 'negatable' },
  { label: 'Name', keyword: 'name', variant: 'negatable' },
  { label: 'Location', keyword: 'location', variant: 'negatable' },
];
const sortOptions = {
  defaultSort: {
    field: 'Name',
    reverse: false,
  },
};
const pageSize = 50;

//-----------------------------------------------------------------------------
function EquipmentPage() {
  const roles = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);

  const [equipmentList, setEquipmentList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);

  React.useEffect(() => {
    setPageButtons(
      <>
        <ListItem sx={{ mb: 1 }}>
          <EquipmentForm setTableReload={setTableLoading} equipmentList={equipmentList} />
        </ListItem>
        {canEdit && (
          <ListItem sx={{ mb: 1 }}>
            <EditButton data-cy="edit-equipment">Edit Equipment</EditButton>
          </ListItem>
        )}
      </>
    );
    return () => setPageButtons(null);
  }, [equipmentList, setPageButtons, canEdit]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getEquipment = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/equipment',
        });
        setEquipmentList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [error, setError, setEquipmentList]);
  useInterval(getEquipment, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  const { search, setSearch, setSearchHelper } = React.useContext(SearchContext);
  const filteredEquipment = useFilter(equipmentList, search, searchFields);
  const [sortedEquipment, handleSortSelection, sort] = useSort(filteredEquipment, sortOptions);
  const location = useLocation();
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setSearchHelper('');
      setTableLoading(true);
    };
  }, [setSearch, setSearchHelper, location]);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageEquipment = React.useMemo(
    () => sortedEquipment.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedEquipment]
  );
  React.useEffect(() => {
    setPage(0);
  }, [search]);

  if (tableLoading) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <EquipmentHeader sort={sort} setSort={handleSortSelection} />
      {pageEquipment.map((equipment) => (
        <EquipmentRow
          key={equipment.Serial}
          equipment={equipment}
          equipmentList={equipmentList}
          setTableReload={setTableLoading}
        />
      ))}
      <Pagination
        pageSize={pageSize}
        page={page}
        setPage={setPage}
        count={sortedEquipment.length}
      />
    </>
  );
}

export default EquipmentPage;
