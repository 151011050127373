import React from 'react';

function FormattedMultilineRow({ cell }) {
  if (!cell) {
    return null;
  }

  const cellList = cell.split(/\r?\n/g);

  return cellList?.map((item, index) => (
    // it is possible for each item to not be unique,
    // there is no particular sorting order as well
    // so disabling this eslint rule should be safe in this case
    // for more information: https://github.com/jsx-eslint/eslint-plugin-react/issues/1123
    // eslint-disable-next-line react/no-array-index-key
    <div key={`cell-${item}-${index}`}>{item}</div>
  ));
}

export default FormattedMultilineRow;
