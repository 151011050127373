import React from 'react';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import HomeRepairService from '@mui/icons-material/HomeRepairService';
// import ViewList from '@mui/icons-material/ViewList';
import Widgets from '@mui/icons-material/Widgets';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import PageContext from '../contexts/PageContext.jsx';
import Alert from './common/Alert.jsx';
import NavButton from './common/NavButton.jsx';

function SideBar({
  // Props
  onClick,
}) {
  const { PageButtons } = React.useContext(PageContext);
  const [version, setVersion] = React.useState('');
  const [error, setError] = React.useState(null);

  const fetchVersion = React.useCallback(async () => {
    try {
      const versionNumber = await axios.get('/api/version');
      setVersion(versionNumber.data.version);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, []);

  React.useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <List onClick={onClick}>
        {!!PageButtons && (
          <>
            {PageButtons}
            <Divider />
          </>
        )}
        <NavButton Icon={<Widgets />} text="Parts" to="/" />
        <NavButton Icon={<HomeRepairService />} text="Equipment" to="/equipment" />
        {/* <NavButton Icon={<ViewList />} text="Materials" to="/Materials" /> */}
      </List>
      <Typography
        data-cy="version-number"
        sx={{
          paddingLeft: 2,
          position: 'absolute',
          bottom: 0,
          paddingBottom: 1,
          fontSize: '0.75rem',
          opacity: 0.7,
        }}
      >
        v{version}
      </Typography>
      <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
    </>
  );
}

export default SideBar;
