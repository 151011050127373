import React from 'react';
import { useLocation } from 'react-router-dom';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import ListItem from '@mui/material/ListItem';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import Pagination from '../../components/common/Pagination.jsx';
import EditButton from '../../components/EditButton.jsx';
import PartsForm from '../../components/PartsForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import PartsHeader from './PartsHeader.jsx';
import PartsRow from './PartsRow.jsx';

//-----------------------------------------------------------------------------
// Page configuration
//-----------------------------------------------------------------------------
const DATA_REFRESH_INTERVAL_MS = 15000;
const searchFields = {
  part: 'PartNumber',
  description: 'Description',
  qcp: 'QualityControlPlanId',
  vendor: 'Vendor',
  notes: 'Notes',
  gtin: 'GTIN',
};
const searchHelper = [
  { label: 'Has the words', keyword: '+', variant: 'global' },
  { label: "Doesn't have", keyword: '-', variant: 'global' },
  { label: 'Part Number', keyword: 'part', variant: 'negatable' },
  { label: 'Quality Control Plan', keyword: 'qcp', variant: 'negatable' },
  { label: 'GTIN', keyword: 'gtin', variant: 'negatable' },
  { label: 'Vendor', keyword: 'vendor', variant: 'negatable' },
  { label: 'Notes', keyword: 'notes', variant: 'negatable' },
];
const sortOptions = {
  defaultSort: {
    field: 'PartNumber',
    reverse: false,
  },
};
const pageSize = 50;

//-----------------------------------------------------------------------------
function PartsPage() {
  const [partList, setPartList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  const roles = useAuth0Roles();
  const canEdit = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      <>
        <ListItem sx={{ mb: 1 }} data-cy="add-parts-sidebar">
          <PartsForm partList={partList} setTableReload={setTableLoading} />
        </ListItem>
        {canEdit && (
          <ListItem sx={{ mb: 1 }} data-cy="edit-parts-sidebar">
            <EditButton data-cy="edit-parts">Edit Parts</EditButton>
          </ListItem>
        )}
      </>
    );
    return () => setPageButtons(null);
  }, [partList, setPageButtons, canEdit]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getParts = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/parts',
        });
        setPartList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [error, setError, setPartList]);
  useInterval(getParts, DATA_REFRESH_INTERVAL_MS, tableLoading);

  //---------------------------------------------------------------------------
  // Search and sort
  //---------------------------------------------------------------------------
  const { search, setSearch, setSearchHelper } = React.useContext(SearchContext);
  const filteredParts = useFilter(partList, search, searchFields);
  const [sortedParts, handleSortSelection, sort] = useSort(filteredParts, sortOptions);
  const location = useLocation();
  React.useEffect(() => {
    setSearchHelper(searchHelper);
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setSearchHelper('');
      setTableLoading(true);
    };
  }, [setSearch, setSearchHelper, location]);

  //---------------------------------------------------------------------------
  // Pagination support
  //---------------------------------------------------------------------------
  const [page, setPage] = React.useState(0);
  const pageParts = React.useMemo(
    () => sortedParts.slice(page * pageSize, (page + 1) * pageSize),
    [page, sortedParts]
  );
  React.useEffect(() => {
    setPage(0);
  }, [search]);

  if (tableLoading) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <PartsHeader sort={sort} setSort={handleSortSelection} />
      {pageParts.map((part) => (
        <PartsRow key={part.PartNumber} part={part} setTableReload={setTableLoading} />
      ))}
      <Pagination pageSize={pageSize} page={page} setPage={setPage} count={sortedParts.length} />
    </>
  );
}

export default PartsPage;
