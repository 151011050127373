import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import ViewList from '@mui/icons-material/ViewList';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function MaterialForm({
  // Props
  material,
  equipment,
  part,
  parameterList,
  setTableReload,
}) {
  const formTitle = material?.PartNumber ? 'Update Material' : 'Add Material';
  const partPartNumber = part?.PartNumber;
  const fullTitle = partPartNumber ? `${formTitle} for ${partPartNumber}` : formTitle;

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Autocomplete lists of Equipment and Materials
  //---------------------------------------------------------------------------
  const [equipmentList, setEquipmentList] = React.useState([equipment || { Serial: 'unknown' }]);
  const [materialsList, setMaterialsList] = React.useState([]);
  const getEquipmentAndMaterials = React.useCallback(async () => {
    try {
      if (!error) {
        const [equipmentResponse, materialsResponse] = await Promise.all([
          axios({
            method: 'GET',
            url: '/api/equipment',
            params: { include: '[]' }, // Skip the table JOINs!
          }),
          await axios({
            method: 'GET',
            url: '/api/materials',
            params: { include: '[]' }, // Skip the table JOINs!
          }),
        ]);
        setEquipmentList(equipmentResponse.data);
        setMaterialsList(materialsResponse.data);
        setError(null);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [error, setError, setEquipmentList]);
  React.useEffect(() => {
    if (open) {
      getEquipmentAndMaterials();
    }
  }, [getEquipmentAndMaterials, open]);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = {
    equipmentSerial: equipment?.Serial || '',
    partNumber: material?.PartNumber || '',
    description: material?.Description || '',
    parameters: parameterList || '',
  };
  const { handleSubmit, control, reset, setValue, watch } = useForm({
    defaultValues,
  });
  const { isDirty } = useFormState({ control });
  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        const encodedPartNumber = encodeURIComponent(data.partNumber);
        await axios({
          method: 'PUT',
          url: `/api/materials/${encodedPartNumber}`,
          data: {
            description: data.description,
            equipmentSerial: data.equipmentSerial,
            partPartNumber,
            parameterList: data.parameters,
          },
        });

        // Reset and close the modal
        reset();
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [partPartNumber, reset, setTableReload]
  );

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset();
    setOpen(false);
  }, [reset]);

  //---------------------------------------------------------------------------
  // Autofill the material description if the PN is in the database
  //---------------------------------------------------------------------------
  const inputPartNumber = watch('partNumber');
  React.useEffect(() => {
    const match = materialsList.find((m) => m.PartNumber === inputPartNumber);
    if (match) {
      setValue('description', match.Description);
    } else {
      setValue('description', '');
    }
  }, [inputPartNumber, materialsList, setValue]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {material?.PartNumber ? (
        <IconButton aria-label="edit" color="secondary" onClick={handleOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button variant="contained" color="secondary" onClick={handleOpen} startIcon={<ViewList />}>
          Add Material
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth id="deviceVariantForm">
        <Alert message={error} setMessage={setError} level="error" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <ViewList color="secondary" sx={{ mr: 2 }} />
          {fullTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                disabled={!!equipment}
                label="Equipment"
                name="equipmentSerial"
                id="equipmentInput"
                defaultValue={defaultValues.equipmentSerial}
                options={equipmentList.map((e) => ({
                  name: e?.Name,
                  id: e?.Serial,
                  key: e?.serial,
                }))}
                required
                rules={{
                  required: 'Please select an Equipment',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                disabled={!!material?.PartNumber}
                label="Part Number"
                name="partNumber"
                id="partNumberInput"
                defaultValue={defaultValues.partNumber}
                required
                rules={{
                  required: 'Part Number is required',
                  maxLength: {
                    value: 20,
                    message: 'Part Number should be less than 20 characters',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Description"
                name="description"
                id="descriptionInput"
                defaultValue={defaultValues.description}
                otherProps={{ multiline: true }}
                required
                rules={{
                  required: 'Please provide a description for the material',
                  maxLength: {
                    value: 100,
                    message: 'Description should be less than 100 characters',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Parameters"
                name="parameters"
                id="parametersInput"
                defaultValue={defaultValues.parameters}
                otherProps={{ multiline: true }}
                rules={{
                  maxLength: {
                    value: 200,
                    message: 'Parameters should be less than 200 characters',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {material?.PartNumber ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaterialForm;
