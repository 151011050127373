import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import HomeRepairService from '@mui/icons-material/HomeRepairService';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

function EquipmentForm({
  // Props
  equipment,
  equipmentList,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (equipment?.Serial ? 'Update Equipment' : 'Add Equipment'),
    [equipment?.Serial]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      equipmentSerial: equipment?.Serial || '',
      name: equipment?.Name || '',
      location: equipment?.Location || '',
      disabled: equipment?.IsDisabled ?? false,
    }),
    [equipment]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        const encodedSerial = encodeURIComponent(data.equipmentSerial);
        await axios({
          method: 'PUT',
          url: `/api/equipment/${encodedSerial}`,
          data,
        });

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [defaultValues, setTableReload, reset, setSuccess]
  );

  //---------------------------------------------------------------------------
  // Disable Confirmation Form
  //---------------------------------------------------------------------------
  const handleDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: true,
    });
  }, [defaultValues, onSubmit]);

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setOpen(false);
  }, [reset, defaultValues]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {equipment?.Serial ? (
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{ display: defaultValues.disabled ? 'none' : 'block' }}
          data-cy={`edit-equipment-row-${equipment?.Serial}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<HomeRepairService />}
          data-cy="add-equipment"
        >
          Add Equipment
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        id="equipmentForm"
        onClick={disablePropagation}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <HomeRepairService color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Name"
                name="name"
                id="nameInput"
                defaultValue={defaultValues.name}
                required
                rules={{
                  required: 'Please provide a name for the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Name should be less 256 than characters',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Location"
                name="location"
                id="locationInput"
                defaultValue={defaultValues.location}
                required
                rules={{
                  required: 'Please provide a location for the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Location should be less 256 than characters',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                disabled={!!equipment}
                control={control}
                label="Serial Number"
                name="equipmentSerial"
                id="equipmentSerialInput"
                defaultValue={defaultValues.equipmentSerial}
                required
                rules={{
                  required: 'Please provide the serial number of the equipment',
                  maxLength: {
                    value: 255,
                    message: 'Serial Number should be less than 256 characters',
                  },
                  validate: {
                    unique: (serial) => {
                      // If we are updating, remove ourselves from the list so we aren't checking ourselves
                      if (
                        equipmentList.some(
                          (equip) => equip.Serial !== equipment?.Serial && equip.Serial === serial
                        )
                      ) {
                        return 'Equipment with this Serial Number already exists';
                      }
                      return true;
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2} sx={{ marginRight: 'auto' }}>
            {!!equipment && (
              <ConfirmDisable
                handleConfirmation={handleDisableConfirmation}
                confirmKeyword={equipment.Serial}
                name="equipment"
              />
            )}
          </Box>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {equipment?.Serial ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EquipmentForm;
