import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import PartsForm from '../../components/PartsForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import EquipmentTable from './EquipmentTable.jsx';
import WorkInstructionsTable from './WorkInstructionsTable.jsx';

function PartsRow({
  // Props
  part,
  setTableReload,
}) {
  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);

  const { editMode } = React.useContext(PageContext);
  const roles = useAuth0Roles();
  const canEdit = React.useMemo(
    () => roles.includes('Progress-Tracker Admin') && !part.IsDisabled,
    [roles, part.IsDisabled]
  );

  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);
  return (
    <Card
      square
      sx={{ opacity: part.IsDisabled ? 0.5 : 1.0, ...(expanded && { my: 1, boxShadow: 6 }) }}
      data-cy={part.PartNumber}
      data-testid={part.IsDisabled ? 'disabled-part-row' : 'enabled-part-row'}
    >
      <Grid
        container
        columns={16}
        onClick={handleExpandClick}
        alignItems="center"
        sx={{
          cursor: 'pointer',
          p: '0.5rem',
          minHeight: '3.5rem',
          ...(expanded && { fontWeight: 'bold' }),
        }}
        data-cy="parts-row"
      >
        <Grid item xs={4} sm={2} data-cy="part-number-column">
          {part.PartNumber}
        </Grid>
        <Grid item xs={7} sm={3} data-cy="description-column">
          {part.Description}
        </Grid>
        <Grid item xs={4} sm={1} data-cy="qcp-column">
          {part.QualityControlPlanId || '-'}
        </Grid>
        <Grid item sm={1} sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="dmr-column">
          {part.DeviceMasterRecord || '-'}
        </Grid>
        <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="gtin-column">
          {part.GTIN || '-'}
        </Grid>
        <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="vendor-column">
          {part.Vendor || '-'}
        </Grid>
        <Grid item sm={3} sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="notes-column">
          <FormattedMultilineRow cell={part.Notes || '-'} />
        </Grid>
        {canEdit && editMode && (
          <Grid
            item
            xs={1}
            sx={{
              display: editMode ? 'block' : 'none',
              marginRight: { md: '0.5rem', sm: 'inherit' },
              marginLeft: { md: 'auto', sm: 'inherit' },
            }}
          >
            <PartsForm part={part} setTableReload={setTableReload} />
          </Grid>
        )}
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <WorkInstructionsTable workInstructions={part.WorkInstructions} />
        <EquipmentTable equipment={part.Equipment} part={part} setTableReload={setTableReload} />
      </Collapse>
    </Card>
  );
}

export default PartsRow;
