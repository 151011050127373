/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Controller } from 'react-hook-form';

import { DatePicker } from '@mui/x-date-pickers';

function FormDatePicker({
  control,
  name,
  label,
  id,
  defaultValue,
  views = ['year', 'month', 'day'],
  required = true,
  disabled = false,
  disableFuture,
  rules,
  openTo,
  ...otherProps
}) {
  return (
    <Controller
      name={name}
      control={control}
      data-cy={`controller-${id}`}
      shouldUnregister
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <DatePicker
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          id={id}
          views={views}
          defaultValue={defaultValue}
          disableFuture={disableFuture}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: 'standard',
              required,
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...otherProps}
        />
      )}
    />
  );
}

export default FormDatePicker;
