import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------

function FormattedParameters({
  // Props
  Parameters,
}) {
  const paramList = Parameters?.ParameterList?.split(/\r?\n/g);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <div>
      {paramList?.map((param) => (
        <div key={`${param}`}> {param}</div>
      ))}
    </div>
  );
}

export default FormattedParameters;
