import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function EquipmentHeader({
  // Props
  sort,
  setSort,
}) {
  //---------------------------------------------------------------------------
  // Update the `sort` object for use by sortManager
  //---------------------------------------------------------------------------
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid container columns={16} id="headersRow" alignItems="center" justifyContent="flex-start">
        <Grid item xs={8} sm={6} md={4}>
          <ColumnHeader
            id="Name"
            display="Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={8} sm={3} md={2}>
          <ColumnHeader
            id="Serial"
            display="Serial Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item sm={3} md={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="Location"
            display="Location"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item md={2} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <ColumnHeader
            id="MaintenanceRequirements"
            display="Maintenance Requirements"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <ColumnHeader
            id="Activities"
            display="Maintenance Activities"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <ColumnHeader
            id="Materials"
            display="Materials"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          <ColumnHeader
            id="CurrentOperatingHours"
            display="Current Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EquipmentHeader;
