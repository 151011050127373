import axios from 'axios';

const httpClient = axios.create({
  paramsSerializer: {
    // Escape the following characters just to be safe
    encode: (val) =>
      val
        .replace(/%/gi, '%25')
        .replace(/\[/gi, '%5B')
        .replace(/\]/gi, '%5D')
        .replace(/\$/gi, '%24'),
  },
});

// adds access tokens in all api requests
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method
export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  httpClient.interceptors.request.use(async (config) => {
    const newConfig = config;
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'progress-tracker.tzmedical.io',
      },
    });
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  });
};

export default httpClient;
