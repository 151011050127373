import React from 'react';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import WarningIcon from '@mui/icons-material/Warning.js';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import EquipmentForm from '../../components/EquipmentForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import MaintenanceActivitiesTable from './MaintenanceActivitiesTable.jsx';
import MaintenanceRequirementsTable from './MaintenanceRequirementsTable.jsx';

function EquipmentRow({
  // Props
  equipment,
  equipmentList,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);

  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);

  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => setExpanded((previous) => !previous), []);
  const exceedsAnyThresholds = React.useMemo(
    () => equipment.MaintenanceRequirements.some((requirement) => requirement.ThresholdExceeded),
    [equipment.MaintenanceRequirements]
  );

  return (
    <Card
      square
      sx={{ opacity: equipment.IsDisabled ? 0.5 : 1.0, ...(expanded && { my: 1, boxShadow: 6 }) }}
      data-cy={equipment.Serial}
      data-testid={equipment.IsDisabled ? 'disabled-equipment-row' : 'enabled-equipment-row'}
    >
      <Grid
        container
        columns={16}
        onClick={handleExpandClick}
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          cursor: 'pointer',
          p: '0.5rem',
          minHeight: '3.5rem',
          ...(expanded && { fontWeight: 'bold' }),
        }}
      >
        <Grid item xs={8} sm={6} md={4}>
          <Stack spacing={1} direction="row" alignItems="center">
            {exceedsAnyThresholds && (
              <WarningIcon color="warning" data-cy={`threshold-exceeded-${equipment.Serial}`} />
            )}
            <div>{equipment.Name}</div>
          </Stack>
        </Grid>
        <Grid item xs={8} sm={3} md={2}>
          {equipment.Serial}
        </Grid>
        <Grid item md={2} sm={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
          {equipment.Location}
        </Grid>
        <Grid item md={2} sm={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          {equipment.MaintenanceRequirements.length}
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          {equipment.Activities.length}
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          {equipment.Materials.length}
        </Grid>
        <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
          {equipment.CurrentOperatingHours || '-'}
        </Grid>
        <Grid
          item
          sx={{
            display: editMode ? 'block' : 'none',
            marginRight: { md: '0.5rem', sm: 'inherit' },
            marginLeft: { md: 'auto', sm: 'inherit' },
          }}
        >
          <EquipmentForm
            equipment={equipment}
            equipmentList={equipmentList}
            setTableReload={setTableReload}
          />
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <MaintenanceRequirementsTable
          maintenanceRequirements={equipment.MaintenanceRequirements}
          equipment={equipment}
          setTableReload={setTableReload}
        />
        <MaintenanceActivitiesTable
          maintenanceActivities={equipment.Activities}
          equipment={equipment}
          setTableReload={setTableReload}
        />
      </Collapse>
    </Card>
  );
}

export default EquipmentRow;
