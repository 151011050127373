import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import ViewList from '@mui/icons-material/ViewList';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DisplayKTDocument from './common/DisplayKTDocument.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

function MaintenanceRequirementsForm({
  // props
  equipment,
  requirement,
  setTableReload,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error and Success alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      // if the interval unit is unknown due to a migration, require the field to be filled out
      intervalUnits: requirement?.IntervalUnits || '',
      intervalPeriod: requirement?.IntervalPeriod || '',
      procedureId: requirement?.ProcedureId || '',
      disabled: requirement?.IsDisabled ?? false,
      notes: requirement?.Notes || '',
    }),
    [requirement]
  );

  const { handleSubmit, control, reset, watch } = useForm({ defaultValues });
  const { isDirty } = useFormState({ control });
  const onSubmit = React.useCallback(
    async (data) => {
      // disable the submit button and display the spinner
      setLoading(true);

      try {
        const encodedSerial = encodeURIComponent(equipment.Serial);
        if (requirement) {
          await axios({
            method: 'PUT',
            url: `/api/equipment/${encodedSerial}/maintenance-requirements/${requirement.Id}`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedureId,
              disabled: data.disabled,
              notes: data.notes,
            },
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/equipment/${encodedSerial}/maintenance-requirements`,
            data: {
              interval: { period: data.intervalPeriod, units: data.intervalUnits },
              procedureId: data.procedureId,
              disabled: false,
              notes: data.notes,
            },
          });
        }

        // reset and close the modal
        reset();
        setOpen(false);

        // update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
        setSuccess(
          requirement
            ? `Successfully edited a Maintenance Requirement on ${equipment.Name}`
            : `Successfully added a new Maintenance Requirement to ${equipment.Name}`
        );
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [equipment.Name, equipment.Serial, requirement, reset, setSuccess, setTableReload]
  );

  //---------------------------------------------------------------------------
  // Form Options
  //---------------------------------------------------------------------------
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  const intervalUnitOptions = React.useMemo(() => {
    const options = [
      { name: 'Days', id: 'Days' },
      { name: 'Operating Hours', id: 'Operating Hours' },
      { name: 'Months', id: 'Months' },
      { name: 'Weeks', id: 'Weeks' },
      { name: 'Years', id: 'Years' },
    ];

    // if the unit is defined as 'unknown' because of a migration, add the option
    // but require the user to update it
    if (requirement?.IntervalUnits?.toLowerCase() === 'unknown') {
      options.push({
        name: <i>Unknown</i>,
        id: 'Unknown',
        disabled: true,
      });
    }

    return options;
  }, [requirement?.IntervalUnits]);

  //---------------------------------------------------------------------------
  // Autocomplete lists of Process Ids
  //---------------------------------------------------------------------------
  const { documents } = React.useContext(DocumentContext);
  const procedureIdField = Number(watch('procedureId'));

  //---------------------------------------------------------------------------
  // Disable Confirmation Form
  //---------------------------------------------------------------------------
  const handleDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: true,
    });
  }, [defaultValues, onSubmit]);

  const enableMaintenanceRequirement = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: false,
    });
  }, [defaultValues, onSubmit]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {requirement ? (
        <IconButton
          aria-label="edit-maintenance-requirement"
          color="secondary"
          onClick={handleOpen}
          data-cy={`edit-maintenance-requirement-${requirement.EquipmentSerial}-${requirement.IntervalPeriod}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<ViewList />}
          data-cy="add-requirements"
        >
          Add Requirement
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        data-cy="maintenance-requirements-form"
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="dialog-error" />
        <DialogTitle>
          <ViewList color="secondary" sx={{ mr: 2 }} /> {requirement ? 'Edit' : 'Add'} Maintenance
          Requirement - {equipment.Name} ({equipment.Serial})
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={6} xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <FormStringInput
                    control={control}
                    label="Interval Period"
                    name="intervalPeriod"
                    id="interval-period"
                    defaultValue={defaultValues.intervalPeriod}
                    type="number"
                    required
                    rules={{
                      required: 'Please enter the period for this maintenance interval',
                    }}
                    inputProps={{ sx: { textAlign: 'right' } }}
                    disabled={requirement?.IsDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormStringInput
                    control={control}
                    label="Interval Units"
                    name="intervalUnits"
                    id="interval-unit"
                    defaultValue={defaultValues.intervalUnits}
                    options={intervalUnitOptions}
                    required
                    rules={{
                      required: 'Please select the units for this maintenance interval',
                      validate: {
                        validateIntervalUnit: (value) => {
                          if (value?.toLowerCase() === 'unknown') {
                            return 'Unknown is not a maintenance interval';
                          }
                          return true;
                        },
                      },
                    }}
                    disabled={requirement?.IsDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormStringInput
                    control={control}
                    label="Procedure ID"
                    name="procedureId"
                    id="procedure-id"
                    defaultValue={defaultValues.procedureId}
                    type="number"
                    required
                    rules={{
                      required: 'Please enter the KT ID for this maintenance procedure',
                      validate: {
                        validateDocumentId: (value) => {
                          if (!documents.some((doc) => doc?.id === Number(value))) {
                            return 'Please provide a valid KT ID for this maintenance procedure';
                          }

                          return true;
                        },
                      },
                    }}
                    disabled={requirement?.IsDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormStringInput
                    control={control}
                    label="Notes"
                    name="notes"
                    id="notes"
                    multiline
                    otherProps={{
                      rows: 4,
                    }}
                    defaultValue={defaultValues.notes}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <DisplayKTDocument documentId={procedureIdField} type="Procedure" />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2} sx={{ marginRight: 'auto' }}>
            {!!requirement && !requirement?.IsDisabled && (
              <ConfirmDisable
                handleConfirmation={handleDisableConfirmation}
                confirmKeyword="DISABLE"
                name="requirement"
              />
            )}
            {requirement?.IsDisabled && (
              <Button
                variant="text"
                data-cy="requirement-enable-link"
                sx={{
                  marginLeft: 'auto',
                  marginRight: '2.5rem',
                  color: 'text.secondary',
                  '&:hover': { textDecoration: 'underline' },
                }}
                onClick={enableMaintenanceRequirement}
              >
                Enable Requirement
              </Button>
            )}
          </Box>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              data-cy="submit-requirements"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {requirement ? 'Edit' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaintenanceRequirementsForm;
