import React from 'react';
import { DateTime } from 'luxon';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import WarningIcon from '@mui/icons-material/Warning.js';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import ExternalLink from '../../components/common/ExternalLink.jsx';
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import MaintenanceRequirementsForm from '../../components/MaintenanceRequirementsForm.jsx';
import DocumentContext from '../../contexts/DocumentContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';

const DAYS_IN_YEAR = 365.244;
const MONTHS_IN_YEAR = 12;

const sortOptions = {
  defaultSort: {
    field: 'interval',
    reverse: false,
  },
  fieldGetters: {
    interval: (requirement) => {
      if (requirement.IntervalUnits === 'Days') {
        return requirement.IntervalPeriod * 1;
      }
      if (requirement.IntervalUnits === 'Weeks') {
        return requirement.IntervalPeriod * 7;
      }
      if (requirement.IntervalUnits === 'Months') {
        return (requirement.IntervalPeriod * DAYS_IN_YEAR) / MONTHS_IN_YEAR;
      }
      if (requirement.IntervalUnits === 'Weeks') {
        return requirement.IntervalPeriod * DAYS_IN_YEAR;
      }

      // Make all "Operating Hours" values really big to sort to the end
      return 1000000 + requirement.IntervalPeriod;
    },
  },
};

const KT_URL = 'https://kt.tzmedical.com/knowledgetree/view.php?fDocumentId=';

function MaintenanceRequirementsTable({
  // Props
  equipment,
  maintenanceRequirements,
  setTableReload,
}) {
  const { documents } = React.useContext(DocumentContext);
  const roles = useAuth0Roles();
  const isAdmin = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);
  const [sortedRequirements, handleSortSelection, sort] = useSort(
    maintenanceRequirements,
    sortOptions
  );

  return (
    <Box sx={{ my: 3 }}>
      <Grid container spacing={2} px={1} alignItems="flex-end" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Maintenance Requirements
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="ProcedureName"
            display="Procedure Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="ProcedureId"
            display="Procedure ID"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="interval"
            display="Interval Period"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="lastPerformed"
            display="Last Performed"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnHeader
            id="operatingHours"
            display="Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedRequirements} displayName="maintenance requirements" />{' '}
      {sortedRequirements.map((requirement) => {
        const procedureName = documents.find(({ id }) => id === requirement.ProcedureId)?.name;

        return (
          <React.Fragment key={requirement.Id}>
            <Tooltip
              PopperProps={{
                'data-cy': 'maintenance-requirement-disabled-tooltip',
              }}
              title={
                requirement.IsDisabled &&
                'This requirement is disabled, if you want to enable it, contact your administrator.'
              }
              followCursor
            >
              <Grid
                container
                spacing={2}
                px={1}
                sx={{
                  opacity: requirement.IsDisabled ? 0.5 : 1.0,
                }}
                data-cy={
                  requirement.IsDisabled
                    ? `disabled-requirement-${requirement.Id}`
                    : `enabled-requirement-${requirement.Id}`
                }
              >
                <Grid item xs={2} data-cy={`procedure-name-${requirement.ProcedureId}`}>
                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <div>
                      {requirement.ThresholdExceeded && (
                        <WarningIcon
                          color="warning"
                          data-cy={`threshold-exceeded-${requirement.IntervalPeriod}-${requirement.IntervalUnits}`}
                        />
                      )}
                    </div>
                    <div>{procedureName || <CircularProgress />}</div>
                  </Stack>
                </Grid>
                <Grid item xs={2} data-cy={`procedure-id-${requirement.ProcedureId}`}>
                  <ExternalLink
                    link={`${KT_URL}${requirement.ProcedureId}`}
                    title={requirement.ProcedureId}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3} data-cy={`interval-period-${requirement.IntervalPeriod}`}>
                      {requirement.IntervalPeriod}
                    </Grid>
                    <Grid item xs={6} data-cy={`interval-units-${requirement.IntervalUnits}`}>
                      {requirement.IntervalUnits}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  {requirement.Notes ? <FormattedMultilineRow cell={requirement.Notes} /> : '-'}
                </Grid>
                <Grid item xs={2}>
                  {requirement.lastPerformed?.ActivityDate
                    ? DateTime.fromISO(requirement.lastPerformed.ActivityDate).toLocaleString(
                        DateTime.DATE_SHORT
                      )
                    : '-'}
                </Grid>
                <Grid item xs={2}>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <div>{requirement.CurrentOperatingHours || '-'}</div>
                    <div>
                      {isAdmin && (
                        <MaintenanceRequirementsForm
                          equipment={equipment}
                          requirement={requirement}
                          setTableReload={setTableReload}
                        />
                      )}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Tooltip>
            <Divider variant="middle" sx={{ mx: -1 }} />
          </React.Fragment>
        );
      })}
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            <MaintenanceRequirementsForm equipment={equipment} setTableReload={setTableReload} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MaintenanceRequirementsTable;
