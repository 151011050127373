import React from 'react';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import OpenInNew from '@mui/icons-material/OpenInNew';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function ExternalLink({ link, title }) {
  return (
    <Link href={link} target="_blank" rel="noreferrer" underline="always">
      <Stack direction="row" spacing={0.25} alignItems="center">
        <Typography>{title}</Typography>
        <OpenInNew fontSize="small" />
      </Stack>
    </Link>
  );
}

export default ExternalLink;
