import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import Widgets from '@mui/icons-material/Widgets';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useCheckExists, useCheckUnique } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import DisplayKTDocument from './common/DisplayKTDocument.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDisable from './ConfirmDisable.jsx';

const SUCCESS_MESSAGE_TIMEOUT_MS = 5000;

function PartsForm({
  // Props
  part,
  partList,
  setTableReload,
}) {
  const formTitle = part?.PartNumber ? 'Update Part' : 'Add Part';

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);
  const [, setSuccess] = useAlertSnackbar('success', SUCCESS_MESSAGE_TIMEOUT_MS);

  const { documents } = React.useContext(DocumentContext);

  //---------------------------------------------------------------------------
  // Autocomplete lists of Equipment and Materials
  //---------------------------------------------------------------------------
  const [allParts, setAllParts] = React.useState(partList || []);
  const checkUniquePartNumber = useCheckUnique(
    allParts,
    'PartNumber',
    part?.PartNumber,
    'A part with this Part Number already exists'
  );

  const checkDocumentExists = useCheckExists(
    documents,
    'id',
    null,
    'Please provide a valid KT ID for this field.'
  );

  const getAllParts = React.useCallback(async () => {
    try {
      if (!error) {
        const partResponse = await axios({
          method: 'GET',
          url: '/api/parts',
          params: { include: '[]' }, // Skip the table JOINs!
        });
        setAllParts(partResponse.data);
        setError(null);
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  }, [error, setError, setAllParts]);
  React.useEffect(() => {
    if (open) {
      getAllParts();
    }
  }, [getAllParts, open]);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      partNumber: part?.PartNumber || '',
      description: part?.Description || '',
      qualityControlPlan: part?.QualityControlPlanId || '',
      deviceMasterRecord: part?.DeviceMasterRecord || '',
      notes: part?.Notes || '',
      vendor: part?.Vendor || '',
      version: part?.Version || '',
      disabled: part?.IsDisabled ?? false,
      gtin: part?.GTIN || '',
    }),
    [part]
  );
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues,
  });
  const { isDirty } = useFormState({ control });
  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        const method = part ? 'PATCH' : 'POST';
        const encodedPartNumber = encodeURIComponent(data.partNumber);

        await axios({
          method,
          url: `/api/parts/${encodedPartNumber}`,
          data: {
            partNumber: data.partNumber,
            description: data.description,
            qualityControlPlan: data.qualityControlPlan,
            deviceMasterRecord: data.deviceMasterRecord,
            notes: data.notes,
            vendor: data.vendor,
            disabled: data.disabled,
            version: data.version,
            gtin: data.gtin,
          },
        });

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);
        setSuccess('Successfully saved changes!');

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [defaultValues, reset, setSuccess, setTableReload, part]
  );

  const handleDisableConfirmation = React.useCallback(async () => {
    await onSubmit({
      ...defaultValues,
      disabled: true,
    });
  }, [defaultValues, onSubmit]);

  const blockClick = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setOpen(false);
  }, [defaultValues, reset]);

  const qcpField = Number(watch('qualityControlPlan'));
  const dmrField = Number(watch('deviceMasterRecord'));

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {part?.PartNumber ? (
        <IconButton
          aria-label="edit"
          color="secondary"
          onClick={handleOpen}
          data-cy={`edit-part-row-${part.PartNumber}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button variant="contained" color="secondary" onClick={handleOpen} startIcon={<Widgets />}>
          Add Part
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        onClick={blockClick}
        maxWidth="md"
        fullWidth
        data-cy="part-form"
      >
        <Alert data-cy="error-alert" message={error} setMessage={setError} level="error" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Widgets color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                disabled={!!part}
                label="Part Number"
                name="partNumber"
                id="partNumberInput"
                defaultValue={defaultValues.partNumber}
                required
                rules={{
                  required: 'Please provide a part number for the part',
                  validate: {
                    unique: checkUniquePartNumber,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Description"
                name="description"
                id="descriptionInput"
                defaultValue={defaultValues.description}
                otherProps={{ multiline: true }}
                required
                rules={{
                  required: 'Please provide a description for the part',
                  maxLength: {
                    value: 100,
                    message: 'Description should be less than 100 characters',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Quality Control Plan"
                name="qualityControlPlan"
                id="qualityControlPlanInput"
                type="number"
                defaultValue={defaultValues.qualityControlPlan}
                rules={{
                  validate: {
                    validateDocumentId: (qcp) => {
                      return checkDocumentExists(Number(qcp));
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DisplayKTDocument documentId={qcpField} type="QCP" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Device Master Record"
                name="deviceMasterRecord"
                id="deviceMasterRecordInput"
                type="number"
                defaultValue={defaultValues.deviceMasterRecord}
                rules={{
                  validate: {
                    validateDocumentId: (dmr) => {
                      return checkDocumentExists(Number(dmr));
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DisplayKTDocument documentId={dmrField} type="DMR" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="Vendor"
                name="vendor"
                id="vendorInput"
                defaultValue={defaultValues.vendor}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                label="GTIN"
                name="gtin"
                id="gtinInput"
                defaultValue={defaultValues.gtin}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormStringInput
                control={control}
                label="Notes"
                name="notes"
                id="notesInput"
                defaultValue={defaultValues.notes}
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2} sx={{ marginRight: 'auto' }}>
            {!!part && (
              <ConfirmDisable
                handleConfirmation={handleDisableConfirmation}
                confirmKeyword={part.PartNumber}
                name="part"
                dialogTitle={part.Description}
              />
            )}
          </Box>
          <Box m={2}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              {part?.PartNumber ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PartsForm;
