import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { DateTime } from 'luxon';

import ViewList from '@mui/icons-material/ViewList';

import LoadingButton from '@mui/lab/LoadingButton';
//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormDatePicker from './common/FormDatePicker.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function MaintenanceActivityForm({ equipment, setTableReload }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);
  const { documents } = React.useContext(DocumentContext);

  // form options
  const today = React.useMemo(() => DateTime.now(), []);
  const maintenanceRequirements = React.useMemo(
    () =>
      equipment?.MaintenanceRequirements.map((requirement) => {
        const document = documents.find(({ id }) => id === requirement.ProcedureId);
        const name = `${document?.name} - ${requirement.IntervalPeriod} ${requirement.IntervalUnits}`;
        return { name, id: requirement.Id, disabled: requirement.IsDisabled };
      }).sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      }),
    [documents, equipment?.MaintenanceRequirements]
  );

  const defaultValues = React.useMemo(
    () => ({
      equipmentSerial: equipment.Serial || '',
      currentOperatingHours: '',
      maintenanceRequirements: [],
      notes: '',
      activityDate: today,
    }),
    [equipment.Serial, today]
  );

  // require current operating hours field if any maintenance requirements depend on operating hours
  const includesOperatingHours = React.useMemo(
    () =>
      equipment.MaintenanceRequirements.some(
        (requirement) => requirement.IntervalUnits === 'Operating Hours'
      ),
    [equipment.MaintenanceRequirements]
  );

  // Form Submission
  const { handleSubmit, control, reset } = useForm({ defaultValues });
  const { isDirty } = useFormState({ control });
  const onSubmit = React.useCallback(
    async (data) => {
      setLoading(true);
      try {
        const encodedSerial = encodeURIComponent(equipment.Serial);
        await axios({
          method: 'POST',
          url: `/api/equipment/${encodedSerial}/maintenance-activity`,
          data: {
            activityDate: data.activityDate.toISO(),
            maintenanceRequirementIds: data.maintenanceRequirements,
            notes: data.notes,
            // if string is empty don't include it
            ...(data.currentOperatingHours.length && {
              currentOperatingHours: data.currentOperatingHours,
            }),
          },
        });
        // reset and close the modal
        reset();
        setOpen(false);

        // update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }
        setSuccess(`Successfully added a new Maintenance Activity to ${equipment.Name}`);
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
      setLoading(false);
    },
    [equipment.Name, equipment.Serial, reset, setSuccess, setTableReload]
  );

  // Handles
  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  // Render
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOpen}
        startIcon={<ViewList />}
        data-cy="add-activity"
      >
        Add Activity
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        data-cy="maintenance-activity-form"
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="dialog-error" />
        <DialogTitle>
          <ViewList color="secondary" sx={{ mr: 2 }} /> Add Maintenance Activity - {equipment.Name}{' '}
          ({equipment.Serial})
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} data-cy="activity-date">
                  <FormDatePicker
                    control={control}
                    id="activity-date"
                    label="Activity Date"
                    name="activityDate"
                    defaultValue={today}
                    disableFuture
                    openTo="day"
                    rules={{
                      required: 'Please enter the date of this activity',
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* current operating hours */}
                  <FormStringInput
                    control={control}
                    id="current-operating-hours"
                    label="Current Operating Hours"
                    name="currentOperatingHours"
                    defaultValue={defaultValues.currentOperatingHours}
                    type="number"
                    required={includesOperatingHours}
                    rules={{
                      required:
                        includesOperatingHours &&
                        'Please enter the current operating hours listed on the machine',
                      validate: {
                        validatePossibleTime: (value) => {
                          if (value < 0) {
                            return 'Please specify a positive time';
                          }
                          return true;
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} data-cy="maintenance-requirements">
                  {/* completed maintenance requirements 
                  based on enabled or disabled maintenance requirements,
                  a multi select drop down */}
                  <FormStringInput
                    control={control}
                    id="maintenance-requirements"
                    label="Maintenance Requirements"
                    name="maintenanceRequirements"
                    defaultValue={defaultValues.maintenanceRequirements}
                    type="array"
                    options={maintenanceRequirements || []}
                    otherProps={{ SelectProps: { multiple: true } }}
                    required
                    rules={{
                      required:
                        'Please select the maintenance requirement fulfilled by this activity',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12}>
                  {/* equipment serial number - prefilled with row's data and disabled */}
                  <FormStringInput
                    control={control}
                    id="serial-number"
                    label="Serial Number"
                    name="serialNumber"
                    defaultValue={defaultValues.equipmentSerial}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} data-cy="activity-notes">
                  {/* notes field */}
                  <FormStringInput
                    control={control}
                    id="notes"
                    label="Notes"
                    name="notes"
                    defaultValue={defaultValues.notes}
                    multiline
                    otherProps={{ rows: 4 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box m={2}>
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleClose}
              id="cancel-activities"
            >
              Cancel
            </CancelButton>
          </Box>
          <Box m={2}>
            <LoadingButton
              data-cy="submit-activities"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            >
              Add
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MaintenanceActivityForm;
